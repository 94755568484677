import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

// images
import testmonial from '../../images/testimonial/1.png'
import client1 from '../../images/testimonial/client1.jpg'
import client2 from '../../images/testimonial/client2.jpg'
import client3 from '../../images/testimonial/client3.jpg'
import client4 from '../../images/testimonial/client4.jpg'
import client5 from '../../images/testimonial/client5.jpg'


const sliders = [
    {
        text: "Your tireless efforts put in, in my legal matter are highly appreciable. Your prompt responses and attention has fetched me the expected result. If it hadn't for your analytical skills and knowledge, the matter wouldn't have been settled by now. I am grateful to you for representing me in the lawsuit with utmost care and concern. Thanks for prioritizing me as a client and doing everything in your power to help me.",
        images: client1,
        title: 'Puneet verma',
        subTitle: ''
    },
    {
        text: 'I contacted a few law offices before … Finally, I chose Legal Corridor …the way they greeted me made me feel like a very important client and took consideration for any conditions I had at the time. I am very grateful for their excellent job! Thankful to Corridor family.',
        images: client2,
        title: 'Rakesh kumar',
        subTitle: ''
    },
    {
        text: 'Legal Corridor was perfectly ready and perfectly timely responsive it was the easiest process ever. This is the second time I have engaged  Legal Corridor for their services and I can describe the experience in one simple word – PLEASURE to deal with professionals like this.',
        images: client3,
        title: 'Ranbir Singh',
        subTitle: ''
    },
    {
        text: 'Legal Corridor were so easy to work with. They were very responsive, on top of the situation, and were able to get me a great settlement. Hope to not need their expertise again, but if I do it’s good to know I have someone I can trust! @ LC',
        images: client4,
        title: 'Vineet Brothers',
        subTitle: ''
    },
    {
        text: 'Great team they got Legal Corridor. They were straight forward with how the process works from the start. It was open communication throughout and I never felt out of the “loop”. Definitely will be returning for the service if needed.',
        images: client5,
        title: 'Ankit Diwedi',
        subTitle: ''
    }
]
const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
};
const Testmonial = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="testimonialImages">
                            <img src={testmonial} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-6">
                        <div className="testimonialContent">
                            <span>What People Say</span>
                            <h2>Client Testimonial</h2>
                            <Slider className="testmonialSlider" {...settings}>
                                {sliders.map((slider, i) => (
                                    <div key={i} className="slideItem">
                                        <p>{slider.text}</p>
                                        <div className="thumbWrap">
                                            <div className="thumbImg">
                                                <img src={slider.images} alt="" />
                                            </div>
                                            <div className="imgContent">
                                                <h4>{slider.title}</h4>
                                                <span>{slider.subTitle}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testmonial