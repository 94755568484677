import React from 'react'
import './style.scss'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const services = [
    { icon: 'fi flaticon-lawyer', subtitle: 'Get Free Consult', title: 'Contact Now' },
    { icon: 'fi flaticon-scale', subtitle: 'Get Free', title: 'Expert Advice' },
    { icon: 'fi flaticon-network', subtitle: 'You Can Easily', title: 'Join Our Team' },
]


const Service = props => {

    const redirectToContactArea = () => {
        window.location.href = '#contactArea';
    };

    return (
        <div className={props.className}>
            <div className="container">
                <div className="row">
                    {services.map((service, i) => (
                        <div key={i} className="col-lg-4 col-md-6" >
                            <div className="serviceItem">
                                <div className="serviceIcon">
                                    <i className={service.icon}></i>
                                </div>
                                <div className="serviceText">
                                    <span>{service.subtitle}</span>
                                    <Button onClick={()=>redirectToContactArea()}  style={{marginTop:"8px",background:"black"}} variant="contained">{service.title}</Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Service