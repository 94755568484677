import React, { useState } from 'react'
import SectionTitle from '../Title'
import './style.scss'

// images
import portfolio1 from '../../images/studies/1.jpg'
import portfolio2 from '../../images/studies/1-2.jpg'
import portfolio3 from '../../images/studies/3.jpg'
import portfolio4 from '../../images/studies/4.jpg'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
// import portfolio5 from '../../images/studies/5.jpg'

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    // { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]
const Portfolio = ({ title, subTitle, className, fullWidth, portfolioItem }) => {

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  const handleKnowMoreClick = (more) => {
    setSelectedPortfolio(more);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };



    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                </div>
            </div>

            {!fullWidth ? (
                <div className="portfolioWrapper">
                    {portfolioItem.map((portfolio, i) => (
                        <div key={i} className="portfolioItem">
                            <img src={portfolio.images} alt="" />
                            <div className="portfolioContent">
                                <p>{portfolio.subtitle}</p>
                                <h3>{portfolio.title}</h3>
                                <div className="btnStyle btnStyle3">
                                    <Button onClick={()=>handleKnowMoreClick(portfolio.knowMore)} style={{background:"#c0b596",color:"white"}}>Know More</Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                    <div className="container gridPortfolio">
                        <div className="row">
                            {portfolioItem.map((portfolio, i) => (
                                <div key={i} className="col-lg-4 col-sm-6 col-12">
                                    <div key={i} className="portfolioItem">
                                        <img src={portfolio.images} alt="" />
                                        <div className="portfolioContent">
                                            <p>{portfolio.subtitle}</p>
                                            <h3>{portfolio.title}</h3>
                                            <div className="btnStyle btnStyle3" >
                                                <Button onClick={()=>handleKnowMoreClick(portfolio)} style={{background:"#c0b596",color:"white"}}>Know More</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPortfolio && selectedPortfolio.title}</DialogTitle>
        <DialogContent>
          {selectedPortfolio && (
            <>
              <p>{selectedPortfolio.subtitle}</p>
              <p>{selectedPortfolio.knowMore}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    )
}
export default Portfolio