import React, { useState, useEffect } from 'react';
import SectionTitle from '../Title';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import './style.scss';

// images
import blog1 from '../../images/blog/1.jpg';
import avatar from '../../images/blog-page/6.jpg';

const BlogArea = ({ className, title, subTitle }) => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

                const response = await axios.get(`${BACKEND_HOST}/blogs`);
                console.log(`${BACKEND_HOST}/blogs`, response);
                setBlogs(response.data.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {loading ? (
                        <p>Loading blogs...</p>
                    ) : blogs.length > 0 ? (
                        blogs.map((blog, i) => (
                            <div key={i} className="col-lg-4 col-sm-6 col-12">
                                <div className="blogWrap">
                                    <div className="blogImage">
                                        <img src={blog.banner_url || blog1} alt="" />
                                    </div>
                                    <div className="blogContent">
                                        <h3><Link to={`/blog-details/${blog.id}`}>{blog.title || 'Blog Title'}</Link></h3>
                                        <ul className="blogMeta">
                                            <li><img src={blog.avatar_url || avatar} alt="" /></li>
                                            <li>{blog.creator_name || 'By Aliza anne'}</li>
                                            <li>{blog.creation_date || 'Oct 12, 2018'}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No blogs available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogArea;
