import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Portfolio from '../../../components/Portfolio'
// images
import breadcumb from '../../../images/Attorneys-single/attorney-team.jpeg'
// images
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/1-2.jpg'
// import portfolio5 from '../../../images/studies/5.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Our Team' }
]

const portfolioItem = [
    { images: portfolio1, title: 'Adv. Aman Verma', subtitle: 'CEO', },
    { images: portfolio2, title: 'Adv. Utkarsh Bhatt', subtitle: 'Associate', },
    { images: portfolio3, title: 'Adv. Aditya Shankar', subtitle: 'Associate', },
    { images: portfolio4, title: 'Adv. Deepak Chauan', subtitle: 'Associate', },
]

const PortfolioPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton
                    className="headerBottomArea headerBottomAreaStyelTwo"
                />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Our Team"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />

            <Portfolio
                title="Meet Our Team"
                subTitle=""
                fullWidth={true}
                portfolioItem={portfolioItem}
                className="portfolioArea portfolioAreaStyleTwo"
            />

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default PortfolioPage