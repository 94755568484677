import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import './style.scss'
const footerLinks = [
    {
        title: 'Quick Link', menus: [
            { name: 'Home', route: '#' },
            { name: 'Practice Area', route: 'practice' },
            { name: 'Our Team', route: 'our-team' },
            // { name: 'Recent Case', route: 'case' },
            { name: 'Our Blog', route: 'blogs' },
        ]
    },
    {
        title: 'Practice Area', menus: [
            { name: 'Divorce Law', route: 'practice' },
            { name: 'Criminal Law', route: 'practice' },
            { name: 'Corporate & Commercial Litigation', route: 'practice' },
            { name: 'Contract Drafting', route: 'practice' },
            { name: 'Real Estate Law', route: 'practice' },
        ]
    },
    {
        title: 'Contact Us', menus: [
            { name: 'Head Office Address' },
            { name: '40, hanuman lane, 2nd floor,  baba kharak singh marg, connaught place, New Delhi-110001', },
            { name: 'India', },
            { name: 'Phone: +91-9555690946', },
            { name: 'Email: contact@legalcorridors.com', },
        ]
    },
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                                <p>ARE YOU HAVING ANY PROBLEMS BUT CAN’T CONSULT TO ANYONE?
                                    Talk to us! We promise we can help you! Call Now! <a href="tel:+919555690946" style={{ color: "yellow" }}>+91-9555690946</a></p>
                            </div>
                        </div>
                        {footerLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>© 2024 Legal Corridors. All rights reserved | Return and Refund Policy | Terms and Conditions</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li>
                                        <Link to="https://www.instagram.com/amanverma_advocate?igsh=ODZ0MG0zY2s4c2U=&utm_source=ig_profile_share&igshid=1d14t1alv3d22">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.youtube.com/@Callyouradvocate">
                                            <i className="fa fa-youtube" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.facebook.com/share/iXLSc6QPjvKstvvg/?mibextid=LQQJ4d">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/in/adv-aman-verma-9986b2225">
                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea