import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import './style.scss';

import blog1 from '../../images/blog-page/1.jpg';
import avatar from '../../images/blog-page/6.jpg';

const BlogPost = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

                const response = await axios.get(`${BACKEND_HOST}/blogs`);
                console.log(`${BACKEND_HOST}/blogs`, response);
                setBlogs(response.data.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <Fragment>
            {loading ? (
                <p>Loading blogs...</p>
            ) : blogs.length > 0 ? (
                blogs.map((blog, i) => (
                    <div key={i} className="blogPostWrapper">
                        <div className="blogPostImg">
                            <img src={blog.banner_url || blog1} alt="" />
                        </div>
                        <div className="blogPostContent">
                            <ul className="blogPostMeta">
                                {/* <li><img src={blog.meta.avatar || avatar} alt="" /></li> */}
                                <li>{blog.creator_name || 'By Aliza anne'}</li>
                                {/* <li>{blog.meta.level || 'Family Law'}</li> */}
                                <li>{blog.creation_date || 'Oct 12, 2018'}</li>
                            </ul>
                            <h3><Link to={`/blog-details/${blog.id}`}>{blog.title || 'Blog Title'}</Link></h3>
                            <p>{blog.content || 'Blog content...'}</p>
                            <Link className="readmore" to={`/blog-details/${blog.id}`}>Read more..</Link>
                        </div>
                    </div>
                ))
            ) : (
                <p>No blogs available</p>
            )}
        </Fragment>
    );
};

export default BlogPost;
